const Impressum = () => {
    return (
        <>
            <div className="legal-text">
                <h1>Impressum</h1>
                <br />
                <h2>Anbieter:</h2>
                <p>Freifahren GbR</p>
                <h2>Vertreten durch die Gesellschafter:</h2>
                <ul>
                    <li>David Brandes</li>
                    <li>Johan Trieloff</li>
                </ul>
                <h2>Kontakt:</h2>
                <p>Adresse: Oberhofer Platz 7, 12009 Berlin</p>
                <p>E-Mail: johan@trieloff.net</p>
                <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h2>
                <ul>
                    <li>David Brandes</li>
                    <li>Johan Trieloff</li>
                </ul>
                <h2>Haftungsausschluss:</h2>
                <p>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                    Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                    Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                </p>
                <h2>Urheberrecht:</h2>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                    Gebrauch gestattet.
                </p>
                <h2>Datenschutz:</h2>
                <p>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Für
                    weitere Informationen sehen sie unsere Datenschutzerklärung.
                </p>
            </div>
        </>
    )
}

export default Impressum
